<template>
  <div class="container container-articulos">
    <div class="lugar-fecha">
      <div>
        <span v-if="articulo.fecha" class="fecha">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar"
            viewBox="0 0 16 16">
            <path
              d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
          </svg>
          {{articulo.fecha}}
        </span>
        <span v-if="articulo.hora" class="hora">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-stopwatch"
            viewBox="0 0 16 16">
            <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5V5.6z" />
            <path
              d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64a.715.715 0 0 1 .012-.013l.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354a.512.512 0 0 1-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5zM8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3z" />
          </svg>
          {{articulo.hora}}hrs.
        </span>
      </div>
      <span v-if="articulo.lugar">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z" />
        </svg>
        {{articulo.lugar}}
      </span>
    </div>
    <h1 class="titulo-articulo">{{articulo.titulo}}</h1>
    <div class="imagenes" v-if="imagenes">
      <template v-for="img in imagenes">
        <img :src="img.img" alt="img.key">
      </template>
    </div>
    <div class="container-articulo" v-html="articulo.articulo"></div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubArticulosDetalle",
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.$route.params.seccion)
        .collection("subsecciones")
        .doc(this.$route.params.subseccion)
        .collection("publicaciones")
        .doc(this.$route.params.publicacion)
        .collection("articulos")
        .doc(this.$route.params.id),
      key: "",
      articulo: {},
      imagenes: []
    };
  },
  created() {
    this.ref.get().then((doc) => {
      if ( doc.exists ) {
        this.key = doc.id;
        this.articulo = doc.data();
      }
      //console.log(this.items)
    });

    this.ref.collection("imagenes").orderBy("orden").onSnapshot((querySnapshot) => {
      this.imagenes = [];
      querySnapshot.forEach((img) => {
        this.imagenes.push({
          key: img.id,
          ...img.data(),
        });
      });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
.container-articulos {
  padding-top: 1.5rem;

  @include media-breakpoint-up(md) {
    padding-top: 3rem;
  }
}

.imagenes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  img {
    margin: 1rem;
    width: 60%;
  }

}
  .lugar-fecha {
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }
    
    span {
      font-family: $font-02;
      font-size: 0.65rem;
      font-weight: 600;
      text-transform: uppercase;
      @include media-breakpoint-up(md) {
        font-size: 0.75rem;
      }
    }
    svg {
      margin-right: 0.25rem;
      
    }
    .fecha,
    .hora {
      margin-right: 1rem;
      display: inline-flex;
      align-items: center;
    }
  }
  .container-articulo {
    margin: 1.25rem 0rem;
    margin-top: 1.25rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin: 1.25rem;
    }

    /deep/ h1 {
      font-family: $font-01;
      font-size: 1.25rem;
      font-weight: 600;
      margin: 1.5rem 0rem;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }

    }
    /deep/ h2 {
      font-size: 1.1rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
      }
    }
    /deep/ h3 {
      font-size: 0.9rem;
      font-weight: 700;
      margin: 1.5rem 0rem;
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
    /deep/ p,
    /deep/ li {
      font-weight: 300;
      text-align: justify;
      font-size: 0.85rem;
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
    /deep/ strong {
      font-weight: bold;
    }

    /deep/ img {
      width: 100%;
      display: block;
      margin: 0 auto;
      text-align: center;
      @include media-breakpoint-up(md) {
        width: 80%;
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
      }
    }

  }
</style>